const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  

function formatDate(d: string) {
    var split = d.split("-");
    return split[2] + "." + split[1] + "." + split[0];
    //var dateObj = new Date(d);
    //return addZeroToNumberUnderTen(dateObj.getDate()) + "." + addZeroToNumberUnderTen(dateObj.getMonth()+1) + "." + dateObj.getFullYear();
}

function formatDateMonthYear(d: string) {
    var split = d.split("-");
    var month = addZeroToNumberUnderTen(Number(split[1]));
    var year = split[0].substr(2, 2);
    return month + "'" + year;
    //var dateObj = new Date(d);
    //return addZeroToNumberUnderTen(dateObj.getMonth()+1) + "'" + dateObj.getFullYear().toString().substr(2, 2);
}

function formatDateWithMonth(d: string) {
   // var dateObj = new Date(d);
   var split = d.split("-");
   var day = Number(split[2]);
    const month = months[Number(split[1])-1];
    return month + " " + (day == 1 ? "1st" : day == 2 ? "2nd" : day == 3 ? "3rd" : day + "th") + " " + split[0];
}

function addZeroToNumberUnderTen(number: number) {
    if(number < 10) {
        return "0" + number;
    }
    return number;
}

export {formatDate, formatDateWithMonth, formatDateMonthYear}