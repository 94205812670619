import React, { createRef, ReactNode, useEffect, useRef, useState } from "react";
import { BiographyContent } from "../content/biographyManager";

import ReactDOMServer from 'react-dom/server';
import { useLocation } from "react-router-dom";

interface BiographySectionProps {
    children: ReactNode,
    img: string,
    height: number,
    className?: string
}

export default function BiographySection(props: BiographySectionProps) {

    var location = useLocation();

    const OFFSET = 100;

    const lastText = useRef<HTMLParagraphElement>();
    const sectionRef = createRef<HTMLDivElement>();
    const [readMoreActive, setReadMoreActive] = useState(false);
    const readMore = createRef<HTMLInputElement>();
    const [fullLastText, setFullLastText] = useState<string>();

    const rmid = "rmid" + props.height;

    useEffect(() => {
        setTimeout(updateReadMore, 500);
        window.addEventListener("resize", updateReadMore);
        return () => {
            window.removeEventListener("resize", updateReadMore);
        }
    });
    useEffect(updateReadMore, [readMore]);

    function updateReadMore() {
        if(sectionRef.current == undefined) return;
        lastText.current = sectionRef.current?.children[0].children[sectionRef.current?.children[0].children.length - 1] as HTMLParagraphElement;
        if(fullLastText != undefined) {
            lastText.current.innerHTML = fullLastText;
        }
        setReadMoreActive((sectionRef.current? sectionRef.current.offsetHeight - props.height : 0) > OFFSET);
        var proportion = sectionRef.current? sectionRef.current.offsetHeight / props.height : 0;
        if(lastText.current == undefined) return;
        if(!fullLastText) {
            setFullLastText(lastText.current.innerHTML);
        }
        const words = lastText.current.innerHTML?.split(" ");
        var amountWords = Math.round(words.length / proportion);
        var output = "";
        for(var i = 0; i < amountWords; i++) {
            output += words[i] + " ";
        }
        var leftText = "";
        for(var i = amountWords; i < words.length; i++) {
            leftText += words[i] + " ";
        }
        if(readMoreActive) {
            lastText.current.innerHTML = output + "<span>" + leftText + "</span>" + '<a class="read-more" onclick="this.parentElement.classList.toggle(&quot;read-more-active&quot;);"></a>';
        }
        //TODO: MAKE READ more button work
        //console.log("resize", readMoreActive, (sectionRef.current? sectionRef.current.offsetHeight - props.height : 0) > OFFSET);
    }

    return <div ref={sectionRef} className={"section" + (props.className ? " " + props.className : "")}>
        <div className="text">
            {props.children}
        </div>
        <div className="img" style={{
            "backgroundImage": "url(" + props.img + ")",
            "height": props.height + "px"
        }}/>
        <input type="hidden" id={rmid} ref={readMore}></input>
    </div>;

}