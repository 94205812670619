import React, { useEffect, useState } from "react";
import ArrowIcon from "../assets/icons/arrow";
import StraightArrowIcon from "../assets/icons/straightArrow";

import "./style/nearestUpcomingBanner.css";
import { loadNearestPerformance, Performance } from "../content/performanceManager";
import { formatDate } from "../utils/formatUtils";

function NearestUpcomingBanner() {

    const [closed, setClosed] = useState(false);

    const [performance, setPerformance] = useState<Performance>();
    const [firstExecute, setFirstExecute] = useState(false);

    useEffect(() => {
        if(firstExecute) return;
        setFirstExecute(true);
        loadNearestPerformance(setPerformance);
    });

    function onScroll() {
        setClosed(true);
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        }
    })

    if(!performance || performance.id == -1) {
        return <></>;
    }

    return <div onClick={() => {
        if(!closed && performance?.link) {
            window.open(performance?.link);
        }
    }} className={"upcoming-banner-wrapper" + (closed ? " closed" : "")}>
        <div className="upcoming-banner" onClick={() => {
            setClosed(false);
            if(performance.link) {
                window.open(performance.link);
            }
        }}>
            <h3 onClick={() => {
                setClosed(true);
            }}>Upcoming</h3>
            <div className="content">
                <h2>{formatDate(performance.date ? performance.date : "0-0-0")} - {performance?.location}</h2>
                <h2>{performance?.category + (performance?.featureShort ? ", with " + performance.featureShort : "")}</h2>
            </div>
            {performance.link ? <StraightArrowIcon/> : <></>}
        </div>
    </div>
}

export default NearestUpcomingBanner;